.content-start {
	align-content: flex-start;
}
.content-end {
	align-content: flex-end;
}
.content-center {
	align-content: center;
}
.content-stretch {
	align-content: stretch;
}
.content-between {
	align-content: space-between;
}
.content-around {
	align-content: space-around;
}
.items-start {
	align-items: flex-start;
}
.items-end {
	align-items: flex-end;
}
.items-center {
	align-items: center;
}
.items-baseline {
	align-items: baseline;
}
.items-stretch {
	align-items: stretch;
}
.self-start {
	align-self: flex-start;
}
.self-end {
	align-self: flex-end;
}
.self-center {
	align-self: center;
}
.self-baseline {
	align-self: baseline;
}
.self-stretch {
	align-self: stretch;
}
.bg-transparent {
	background-color: transparent;
}
.bg-trueblack {
	background-color: black;
}
.bg-black {
	background-color: #3c3c3c;
}
.bg-gray {
	background-color: #4f525c;
}
.bg-lightgray {
	background-color: #9ca0aa;
}
.bg-white {
	background-color: white;
}
.bg-green {
	background-color: #6fa94d;
}
.bg-lightgreen {
	background-color: #e2eedb;
}
.bg-purple {
	background-color: #4c495a;
}
.bg-light-error-color {
	background-color: #FEE7E7;
}
.bg-light-warning-color {
	background-color: #FEFAE7;
}
.bg-light-status-color {
	background-color: #EFFCE8;
}
.bg-transparent-90, .bg-trueblack-90 {
	background-color: rgba(0, 0, 0, 0.9);
}
.bg-transparent-80, .bg-trueblack-80 {
	background-color: rgba(0, 0, 0, 0.8);
}
.bg-transparent-70, .bg-trueblack-70 {
	background-color: rgba(0, 0, 0, 0.7);
}
.bg-transparent-60, .bg-trueblack-60 {
	background-color: rgba(0, 0, 0, 0.6);
}
.bg-transparent-50, .bg-trueblack-50 {
	background-color: rgba(0, 0, 0, 0.5);
}
.bg-transparent-40, .bg-trueblack-40 {
	background-color: rgba(0, 0, 0, 0.4);
}
.bg-transparent-30, .bg-trueblack-30 {
	background-color: rgba(0, 0, 0, 0.3);
}
.bg-black-90 {
	background-color: rgba(60, 60, 60, 0.9);
}
.bg-black-80 {
	background-color: rgba(60, 60, 60, 0.8);
}
.bg-black-70 {
	background-color: rgba(60, 60, 60, 0.7);
}
.bg-black-60 {
	background-color: rgba(60, 60, 60, 0.6);
}
.bg-black-50 {
	background-color: rgba(60, 60, 60, 0.5);
}
.bg-black-40 {
	background-color: rgba(60, 60, 60, 0.4);
}
.bg-black-30 {
	background-color: rgba(60, 60, 60, 0.3);
}
.bg-gray-90 {
	background-color: rgba(79, 82, 92, 0.9);
}
.bg-gray-80 {
	background-color: rgba(79, 82, 92, 0.8);
}
.bg-gray-70 {
	background-color: rgba(79, 82, 92, 0.7);
}
.bg-gray-60 {
	background-color: rgba(79, 82, 92, 0.6);
}
.bg-gray-50 {
	background-color: rgba(79, 82, 92, 0.5);
}
.bg-gray-40 {
	background-color: rgba(79, 82, 92, 0.4);
}
.bg-gray-30 {
	background-color: rgba(79, 82, 92, 0.3);
}
.bg-lightgray-90 {
	background-color: rgba(156, 160, 170, 0.9);
}
.bg-lightgray-80 {
	background-color: rgba(156, 160, 170, 0.8);
}
.bg-lightgray-70 {
	background-color: rgba(156, 160, 170, 0.7);
}
.bg-lightgray-60 {
	background-color: rgba(156, 160, 170, 0.6);
}
.bg-lightgray-50 {
	background-color: rgba(156, 160, 170, 0.5);
}
.bg-lightgray-40 {
	background-color: rgba(156, 160, 170, 0.4);
}
.bg-lightgray-30 {
	background-color: rgba(156, 160, 170, 0.3);
}
.bg-white-90 {
	background-color: rgba(255, 255, 255, 0.9);
}
.bg-white-80 {
	background-color: rgba(255, 255, 255, 0.8);
}
.bg-white-70 {
	background-color: rgba(255, 255, 255, 0.7);
}
.bg-white-60 {
	background-color: rgba(255, 255, 255, 0.6);
}
.bg-white-50 {
	background-color: rgba(255, 255, 255, 0.5);
}
.bg-white-40 {
	background-color: rgba(255, 255, 255, 0.4);
}
.bg-white-30 {
	background-color: rgba(255, 255, 255, 0.3);
}
.bg-green-90 {
	background-color: rgba(111, 169, 77, 0.9);
}
.bg-green-80 {
	background-color: rgba(111, 169, 77, 0.8);
}
.bg-green-70 {
	background-color: rgba(111, 169, 77, 0.7);
}
.bg-green-60 {
	background-color: rgba(111, 169, 77, 0.6);
}
.bg-green-50 {
	background-color: rgba(111, 169, 77, 0.5);
}
.bg-green-40 {
	background-color: rgba(111, 169, 77, 0.4);
}
.bg-green-30 {
	background-color: rgba(111, 169, 77, 0.3);
}
.bg-lightgreen-90 {
	background-color: rgba(226, 238, 219, 0.9);
}
.bg-lightgreen-80 {
	background-color: rgba(226, 238, 219, 0.8);
}
.bg-lightgreen-70 {
	background-color: rgba(226, 238, 219, 0.7);
}
.bg-lightgreen-60 {
	background-color: rgba(226, 238, 219, 0.6);
}
.bg-lightgreen-50 {
	background-color: rgba(226, 238, 219, 0.5);
}
.bg-lightgreen-40 {
	background-color: rgba(226, 238, 219, 0.4);
}
.bg-lightgreen-30 {
	background-color: rgba(226, 238, 219, 0.3);
}
.bg-purple-90 {
	background-color: rgba(76, 73, 90, 0.9);
}
.bg-purple-80 {
	background-color: rgba(76, 73, 90, 0.8);
}
.bg-purple-70 {
	background-color: rgba(76, 73, 90, 0.7);
}
.bg-purple-60 {
	background-color: rgba(76, 73, 90, 0.6);
}
.bg-purple-50 {
	background-color: rgba(76, 73, 90, 0.5);
}
.bg-purple-40 {
	background-color: rgba(76, 73, 90, 0.4);
}
.bg-purple-30 {
	background-color: rgba(76, 73, 90, 0.3);
}
.bg-cover {
	background-position: center center;
}
.bg-cover {
	background-size: cover;
}
.bg-contain {
	background-size: contain;
}
.bg-fade-top-transparent {
	background-image: linear-gradient(to top, transparent, rgba(0, 0, 0, 0));
}
.bg-fade-top-trueblack {
	background-image: linear-gradient(to top, black, rgba(0, 0, 0, 0));
}
.bg-fade-top-black {
	background-image: linear-gradient(to top, #3c3c3c, rgba(60, 60, 60, 0));
}
.bg-fade-top-gray {
	background-image: linear-gradient(to top, #4f525c, rgba(79, 82, 92, 0));
}
.bg-fade-top-lightgray {
	background-image: linear-gradient(to top, #9ca0aa, rgba(156, 160, 170, 0));
}
.bg-fade-top-white {
	background-image: linear-gradient(to top, white, rgba(255, 255, 255, 0));
}
.bg-fade-top-green {
	background-image: linear-gradient(to top, #6fa94d, rgba(111, 169, 77, 0));
}
.bg-fade-top-lightgreen {
	background-image: linear-gradient(to top, #e2eedb, rgba(226, 238, 219, 0));
}
.bg-fade-top-purple {
	background-image: linear-gradient(to top, #4c495a, rgba(76, 73, 90, 0));
}
.bg-fade-right-transparent {
	background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0));
}
.bg-fade-right-trueblack {
	background-image: linear-gradient(to right, black, rgba(0, 0, 0, 0));
}
.bg-fade-right-black {
	background-image: linear-gradient(to right, #3c3c3c, rgba(60, 60, 60, 0));
}
.bg-fade-right-gray {
	background-image: linear-gradient(to right, #4f525c, rgba(79, 82, 92, 0));
}
.bg-fade-right-lightgray {
	background-image: linear-gradient(to right, #9ca0aa, rgba(156, 160, 170, 0));
}
.bg-fade-right-white {
	background-image: linear-gradient(to right, white, rgba(255, 255, 255, 0));
}
.bg-fade-right-green {
	background-image: linear-gradient(to right, #6fa94d, rgba(111, 169, 77, 0));
}
.bg-fade-right-lightgreen {
	background-image: linear-gradient(to right, #e2eedb, rgba(226, 238, 219, 0));
}
.bg-fade-right-purple {
	background-image: linear-gradient(to right, #4c495a, rgba(76, 73, 90, 0));
}
.bg-fade-bottom-transparent {
	background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0));
}
.bg-fade-bottom-trueblack {
	background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0));
}
.bg-fade-bottom-black {
	background-image: linear-gradient(to bottom, #3c3c3c, rgba(60, 60, 60, 0));
}
.bg-fade-bottom-gray {
	background-image: linear-gradient(to bottom, #4f525c, rgba(79, 82, 92, 0));
}
.bg-fade-bottom-lightgray {
	background-image: linear-gradient(to bottom, #9ca0aa, rgba(156, 160, 170, 0));
}
.bg-fade-bottom-white {
	background-image: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
}
.bg-fade-bottom-green {
	background-image: linear-gradient(to bottom, #6fa94d, rgba(111, 169, 77, 0));
}
.bg-fade-bottom-lightgreen {
	background-image: linear-gradient(to bottom, #e2eedb, rgba(226, 238, 219, 0));
}
.bg-fade-bottom-purple {
	background-image: linear-gradient(to bottom, #4c495a, rgba(76, 73, 90, 0));
}
.bg-fade-left-transparent {
	background-image: linear-gradient(to left, transparent, rgba(0, 0, 0, 0));
}
.bg-fade-left-trueblack {
	background-image: linear-gradient(to left, black, rgba(0, 0, 0, 0));
}
.bg-fade-left-black {
	background-image: linear-gradient(to left, #3c3c3c, rgba(60, 60, 60, 0));
}
.bg-fade-left-gray {
	background-image: linear-gradient(to left, #4f525c, rgba(79, 82, 92, 0));
}
.bg-fade-left-lightgray {
	background-image: linear-gradient(to left, #9ca0aa, rgba(156, 160, 170, 0));
}
.bg-fade-left-white {
	background-image: linear-gradient(to left, white, rgba(255, 255, 255, 0));
}
.bg-fade-left-green {
	background-image: linear-gradient(to left, #6fa94d, rgba(111, 169, 77, 0));
}
.bg-fade-left-lightgreen {
	background-image: linear-gradient(to left, #e2eedb, rgba(226, 238, 219, 0));
}
.bg-fade-left-purple {
	background-image: linear-gradient(to left, #4c495a, rgba(76, 73, 90, 0));
}
.border-top, .border-top-solid, .border-top-thick {
	border-top-style: solid;
}
.border-top, .border-top-dashed {
	border-top-width: 1px;
}
.border-top {
	border-top-color: rgba(0, 0, 0, 0.15);
}
.border-right {
	border-right-style: solid;
}
.border-right {
	border-right-width: 1px;
}
.border-right {
	border-right-color: rgba(0, 0, 0, 0.15);
}
.border-bottom, .border-bottom-solid, .border-bottom-thick {
	border-bottom-style: solid;
}
.border-bottom, .border-bottom-dashed {
	border-bottom-width: 1px;
}
.border-bottom {
	border-bottom-color: rgba(0, 0, 0, 0.15);
}
.border-left {
	border-left-style: solid;
}
.border-left {
	border-left-width: 1px;
}
.border-left {
	border-left-color: rgba(0, 0, 0, 0.15);
}
.border-top-dashed {
	border-top-style: dashed;
}
.border-top-dashed, .border-top-dotted, .border-top-solid, .border-top-thick {
	border-top-color: currentColor;
}
.border-top-dotted {
	border-top-style: dotted;
}
.border-top-dotted, .border-top-solid {
	border-top-width: 2px;
}
.border-top-thick {
	border-top-width: 4px;
}
.border-bottom-dashed {
	border-bottom-style: dashed;
}
.border-bottom-dashed, .border-bottom-dotted, .border-bottom-solid, .border-bottom-thick {
	border-bottom-color: currentColor;
}
.border-bottom-dotted {
	border-bottom-style: dotted;
}
.border-bottom-dotted, .border-bottom-solid {
	border-bottom-width: 2px;
}
.border-bottom-thick {
	border-bottom-width: 4px;
}
.border-transparent {
	border-color: transparent;
}
.border-trueblack {
	border-color: black;
}
.border-black {
	border-color: #3c3c3c;
}
.border-gray {
	border-color: #4f525c;
}
.border-lightgray {
	border-color: #9ca0aa;
}
.border-white {
	border-color: white;
}
.border-green {
	border-color: #6fa94d;
}
.border-lightgreen {
	border-color: #e2eedb;
}
.border-purple {
	border-color: #4c495a;
}
.circle {
	border-radius: 9999px;
}
.rounded {
	border-radius: 6px;
}
.rounded-top {
	border-radius: 6px 6px 0 0;
}
.rounded-right {
	border-radius: 0 6px 6px 0;
}
.rounded-bottom {
	border-radius: 0 0 6px 6px;
}
.rounded-left {
	border-radius: 6px 0 0 6px;
}
.not-rounded {
	border-radius: 0;
}
.active-lighten:active, .focus-lighten:focus, .hover-lighten:hover, .lighten {
	box-shadow: 0 0 0 9999px rgba(255, 255, 255, 0.25) inset;
}
.active-darken:active, .darken, .focus-darken:focus, .hover-darken:hover {
	box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.125) inset;
}
.active-outline:active, .focus-outline:focus, .hover-outline:hover, .outline {
	box-shadow: 0 0 0 2px rgba(79, 82, 92, 0.5);
}
.active-shadow:active, .focus-shadow:focus, .hover-shadow:hover, .shadow {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
}
.active-dark-glow:active, .dark-glow, .focus-dark-glow:focus, .hover-dark-glow:hover {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.75);
}
.border-box {
	box-sizing: border-box;
}
.active-color-inherit:active, .color-inherit, .focus-color-inherit:focus, .hover-color-inherit:hover {
	color: inherit;
}
.active-transparent:active, .focus-transparent:focus, .hover-transparent:hover, .transparent {
	color: transparent;
}
.active-trueblack:active, .focus-trueblack:focus, .hover-trueblack:hover, .trueblack {
	color: black;
}
.active-black:active, .black, .focus-black:focus, .hover-black:hover {
	color: #3c3c3c;
}
.active-gray:active, .focus-gray:focus, .gray, .hover-gray:hover {
	color: #4f525c;
}
.active-lightgray:active, .focus-lightgray:focus, .hover-lightgray:hover, .lightgray {
	color: #9ca0aa;
}
.active-white:active, .focus-white:focus, .hover-white:hover, .white {
	color: white;
}
.active-green:active, .focus-green:focus, .green, .hover-green:hover {
	color: #6fa94d;
}
.active-lightgreen:active, .focus-lightgreen:focus, .hover-lightgreen:hover, .lightgreen {
	color: #e2eedb;
}
.active-purple:active, .focus-purple:focus, .hover-purple:hover, .purple {
	color: #4c495a;
}
.active-dark-error-color:active, .dark-error-color, .focus-dark-error-color:focus, .hover-dark-error-color:hover {
	color: #910808;
}
.active-dark-warning-color:active, .dark-warning-color, .focus-dark-warning-color:focus, .hover-dark-warning-color:hover {
	color: #794906;
}
.active-dark-status-color:active, .dark-status-color, .focus-dark-status-color:focus, .hover-dark-status-color:hover {
	color: #255C0A;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.cursor-progress {
	cursor: progress;
}
.table {
	display: table;
}
.block {
	display: block;
}
.inline-block {
	display: inline-block;
}
.inline {
	display: inline;
}
.flex {
	display: flex;
}
.inline-flex {
	display: inline-flex;
}
.hide {
	display: none;
}
.flex-none {
	flex: none;
}
.flex-auto, .flex-fluid, .flex-grow {
	flex-grow: 1;
}
.flex-auto, .flex-fluid, .flex-shrink {
	flex-shrink: 1;
}
.flex-auto, .flex-shrink {
	flex-basis: auto;
}
.flex-auto, .flex-fluid, .flex-shrink {
	min-width: 0;
}
.flex-auto, .flex-fluid, .flex-shrink {
	min-height: 0;
}
.flex-fluid {
	flex-basis: 0px;
}
.flex-shrink {
	flex-grow: 0;
}
.flex-grow {
	flex-shrink: 0;
}
.flex-column {
	flex-direction: column;
}
.flex-wrap {
	flex-wrap: wrap;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.default-font {
	font-family: Fira Sans, sans-serif;
}
.serif {
	font-family: Palatino, Georgia, serif;
}
.condensed {
	font-family: avenir next condensed, arial narrow, serif;
}
.h0 {
	font-size: 4rem;
}
.h1 {
	font-size: 3.875rem;
}
.h2 {
	font-size: 3rem;
}
.h3 {
	font-size: 2.25rem;
}
.h4 {
	font-size: 1.75rem;
}
.h5 {
	font-size: 1.5rem;
}
.h6 {
	font-size: 1.25rem;
}
.italic {
	font-style: italic;
}
.light {
	font-weight: 300;
}
.regular {
	font-weight: 500;
}
.bold {
	font-weight: 700;
}
.height-100 {
	height: 100%;
}
.height-auto {
	height: auto;
}
.justify-start {
	justify-content: flex-start;
}
.justify-end {
	justify-content: flex-end;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.letter-spacing {
	letter-spacing: .1em;
}
.lh-copy {
	line-height: 1.5;
}
.lh-heading {
	line-height: 1.25;
}
.lh-solid {
	line-height: 1;
}
.m0, .mt0, .my0 {
	margin-top: 0;
}
.m1, .mt1, .my1 {
	margin-top: 0.25rem;
}
.m2, .mt2, .my2 {
	margin-top: 0.5rem;
}
.m3, .mt3, .my3 {
	margin-top: 1rem;
}
.m4, .mt4, .my4 {
	margin-top: 2rem;
}
.m5, .mt5, .my5 {
	margin-top: 4rem;
}
.m6, .mt6, .my6 {
	margin-top: 8rem;
}
.mn1, .mtn1, .myn1 {
	margin-top: -0.25rem;
}
.mn2, .mtn2, .myn2 {
	margin-top: -0.5rem;
}
.mn3, .mtn3, .myn3 {
	margin-top: -1rem;
}
.mn4, .mtn4, .myn4 {
	margin-top: -2rem;
}
.mn5, .mtn5, .myn5 {
	margin-top: -4rem;
}
.mn6, .mtn6, .myn6 {
	margin-top: -8rem;
}
.m0, .mr0, .mx0 {
	margin-right: 0;
}
.m1, .mr1, .mx1 {
	margin-right: 0.25rem;
}
.m2, .mr2, .mx2 {
	margin-right: 0.5rem;
}
.m3, .mr3, .mx3 {
	margin-right: 1rem;
}
.m4, .mr4, .mx4 {
	margin-right: 2rem;
}
.m5, .mr5, .mx5 {
	margin-right: 4rem;
}
.m6, .mr6, .mx6 {
	margin-right: 8rem;
}
.mn1, .mrn1, .mxn1 {
	margin-right: -0.25rem;
}
.mn2, .mrn2, .mxn2 {
	margin-right: -0.5rem;
}
.mn3, .mrn3, .mxn3 {
	margin-right: -1rem;
}
.mn4, .mrn4, .mxn4 {
	margin-right: -2rem;
}
.mn5, .mrn5, .mxn5 {
	margin-right: -4rem;
}
.mn6, .mrn6, .mxn6 {
	margin-right: -8rem;
}
.m0, .mb0, .my0 {
	margin-bottom: 0;
}
.m1, .mb1, .my1 {
	margin-bottom: 0.25rem;
}
.m2, .mb2, .my2 {
	margin-bottom: 0.5rem;
}
.m3, .mb3, .my3 {
	margin-bottom: 1rem;
}
.m4, .mb4, .my4 {
	margin-bottom: 2rem;
}
.m5, .mb5, .my5 {
	margin-bottom: 4rem;
}
.m6, .mb6, .my6 {
	margin-bottom: 8rem;
}
.mbn1, .mn1, .myn1 {
	margin-bottom: -0.25rem;
}
.mbn2, .mn2, .myn2 {
	margin-bottom: -0.5rem;
}
.mbn3, .mn3, .myn3 {
	margin-bottom: -1rem;
}
.mbn4, .mn4, .myn4 {
	margin-bottom: -2rem;
}
.mbn5, .mn5, .myn5 {
	margin-bottom: -4rem;
}
.mbn6, .mn6, .myn6 {
	margin-bottom: -8rem;
}
.m0, .ml0, .mx0 {
	margin-left: 0;
}
.m1, .ml1, .mx1 {
	margin-left: 0.25rem;
}
.m2, .ml2, .mx2 {
	margin-left: 0.5rem;
}
.m3, .ml3, .mx3 {
	margin-left: 1rem;
}
.m4, .ml4, .mx4 {
	margin-left: 2rem;
}
.m5, .ml5, .mx5 {
	margin-left: 4rem;
}
.m6, .ml6, .mx6 {
	margin-left: 8rem;
}
.mln1, .mn1, .mxn1 {
	margin-left: -0.25rem;
}
.mln2, .mn2, .mxn2 {
	margin-left: -0.5rem;
}
.mln3, .mn3, .mxn3 {
	margin-left: -1rem;
}
.mln4, .mn4, .mxn4 {
	margin-left: -2rem;
}
.mln5, .mn5, .mxn5 {
	margin-left: -4rem;
}
.mln6, .mn6, .mxn6 {
	margin-left: -8rem;
}
.mx-auto {
	margin-right: auto;
}
.mx-auto {
	margin-left: auto;
}
.maxw-container-md {
	max-width: 800px;
}
.maxw-container-xl {
	max-width: 1920px;
}
.maxw-100 {
	max-width: 100%;
}
.minw-100 {
	min-width: 100%;
}
.img-cover {
	object-fit: cover;
}
.active-opacity-50:active, .focus-opacity-50:focus, .hover-opacity-50:hover, .opacity-50 {
	opacity: 0.5;
}
.active-opacity-80:active, .focus-opacity-80:focus, .hover-opacity-80:hover, .opacity-80 {
	opacity: 0.8;
}
.active-opacity-90:active, .focus-opacity-90:focus, .hover-opacity-90:hover, .opacity-90 {
	opacity: 0.9;
}
.active-opacity-100:active, .focus-opacity-100:focus, .hover-opacity-100:hover, .opacity-100 {
	opacity: 1;
}
.overflow-hidden {
	overflow: hidden;
}
.overflow-auto {
	overflow: auto;
}
.overflow-scroll {
	overflow: scroll;
}
.overflow-scroll {
	-webkit-overflow-scrolling: touch;
}
.break-word {
	overflow-wrap: break-word;
}
.p0, .pt0, .py0 {
	padding-top: 0;
}
.p1, .pt1, .py1 {
	padding-top: 0.25rem;
}
.p2, .pt2, .py2 {
	padding-top: 0.5rem;
}
.p3, .pt3, .py3 {
	padding-top: 1rem;
}
.p4, .pt4, .py4 {
	padding-top: 2rem;
}
.p5, .pt5, .py5 {
	padding-top: 4rem;
}
.p6, .pt6, .py6 {
	padding-top: 8rem;
}
.p0, .pr0, .px0 {
	padding-right: 0;
}
.p1, .pr1, .px1 {
	padding-right: 0.25rem;
}
.p2, .pr2, .px2 {
	padding-right: 0.5rem;
}
.p3, .pr3, .px3 {
	padding-right: 1rem;
}
.p4, .pr4, .px4 {
	padding-right: 2rem;
}
.p5, .pr5, .px5 {
	padding-right: 4rem;
}
.p6, .pr6, .px6 {
	padding-right: 8rem;
}
.p0, .pb0, .py0 {
	padding-bottom: 0;
}
.p1, .pb1, .py1 {
	padding-bottom: 0.25rem;
}
.p2, .pb2, .py2 {
	padding-bottom: 0.5rem;
}
.p3, .pb3, .py3 {
	padding-bottom: 1rem;
}
.p4, .pb4, .py4 {
	padding-bottom: 2rem;
}
.p5, .pb5, .py5 {
	padding-bottom: 4rem;
}
.p6, .pb6, .py6 {
	padding-bottom: 8rem;
}
.p0, .pl0, .px0 {
	padding-left: 0;
}
.p1, .pl1, .px1 {
	padding-left: 0.25rem;
}
.p2, .pl2, .px2 {
	padding-left: 0.5rem;
}
.p3, .pl3, .px3 {
	padding-left: 1rem;
}
.p4, .pl4, .px4 {
	padding-left: 2rem;
}
.p5, .pl5, .px5 {
	padding-left: 4rem;
}
.p6, .pl6, .px6 {
	padding-left: 8rem;
}
.ratio-square {
	padding-bottom: 100%;
}
.ratio-half-square {
	padding-bottom: 50%;
}
.ratio-16by9 {
	padding-bottom: 56.25%;
}
.ratio-jumbotron-lg {
	padding-bottom: 47.916667%;
}
.ratio-jumbotron-md {
	padding-bottom: 31.25%;
}
.pointer-events-none {
	pointer-events: none;
}
.absolute {
	position: absolute;
}
.relative {
	position: relative;
}
.fixed {
	position: fixed;
}
.table-fixed {
	table-layout: fixed;
}
.left {
	text-align: left;
}
.center {
	text-align: center;
}
.right {
	text-align: right;
}
.uppercase {
	text-transform: uppercase;
}
.text-transform-none {
	text-transform: none;
}
.active-underline-inherit:active, .focus-underline-inherit:focus, .hover-underline-inherit:hover, .underline-inherit {
	text-decoration: inherit;
}
.active-underline:active, .focus-underline:focus, .hover-underline:hover, .underline {
	text-decoration: underline;
}
.active-underline-none:active, .focus-underline-none:focus, .hover-underline-none:hover, .underline-none {
	text-decoration: none;
}
.active-text-shadow-black:active, .focus-text-shadow-black:focus, .hover-text-shadow-black:hover, .text-shadow-black {
	text-shadow: 2px 4px 5px rgba(0,0,0,.6);
}
.top-0, .trbl {
	top: 0;
}
.right-0, .trbl {
	right: 0;
}
.bottom-0, .trbl {
	bottom: 0;
}
.left-0, .trbl {
	left: 0;
}
.valign-top {
	vertical-align: top;
}
.valign-middle {
	vertical-align: middle;
}
.valign-bottom {
	vertical-align: bottom;
}
.valign-icon {
	vertical-align: -5%;
}
.invisible {
	visibility: hidden;
}
.nowrap {
	white-space: nowrap;
}
.preline {
	white-space: pre-line;
}
.width-100vw {
	width: 100vw;
}
.width-auto {
	width: auto;
}
.col12, .width-100 {
	width: 100%;
}
.col1 {
	width: 8.333333333333332%;
}
.col2 {
	width: 16.666666666666664%;
}
.col3 {
	width: 25%;
}
.col4 {
	width: 33.33333333333333%;
}
.col5 {
	width: 41.66666666666667%;
}
.col6 {
	width: 50%;
}
.col7 {
	width: 58.333333333333336%;
}
.col8 {
	width: 66.66666666666666%;
}
.col9 {
	width: 75%;
}
.col10 {
	width: 83.33333333333334%;
}
.col11 {
	width: 91.66666666666666%;
}
.break-word {
	word-wrap: break-word;
}
.z1 {
	z-index: 1;
}
.z2 {
	z-index: 2;
}
.z3 {
	z-index: 3;
}
.z4 {
	z-index: 4;
}
@media (max-width: 479px) {
	.sm-content-start {
		align-content: flex-start;
	}
	.sm-content-end {
		align-content: flex-end;
	}
	.sm-content-center {
		align-content: center;
	}
	.sm-content-stretch {
		align-content: stretch;
	}
	.sm-content-between {
		align-content: space-between;
	}
	.sm-content-around {
		align-content: space-around;
	}
	.sm-items-start {
		align-items: flex-start;
	}
	.sm-items-end {
		align-items: flex-end;
	}
	.sm-items-center {
		align-items: center;
	}
	.sm-items-baseline {
		align-items: baseline;
	}
	.sm-items-stretch {
		align-items: stretch;
	}
	.sm-self-start {
		align-self: flex-start;
	}
	.sm-self-end {
		align-self: flex-end;
	}
	.sm-self-center {
		align-self: center;
	}
	.sm-self-baseline {
		align-self: baseline;
	}
	.sm-self-stretch {
		align-self: stretch;
	}
	.sm-table {
		display: table;
	}
	.sm-block {
		display: block;
	}
	.sm-inline-block {
		display: inline-block;
	}
	.sm-inline {
		display: inline;
	}
	.sm-flex {
		display: flex;
	}
	.sm-inline-flex {
		display: inline-flex;
	}
	.sm-hide {
		display: none;
	}
	.sm-flex-none {
		flex: none;
	}
	.sm-flex-auto, .sm-flex-fluid, .sm-flex-grow {
		flex-grow: 1;
	}
	.sm-flex-auto, .sm-flex-fluid, .sm-flex-shrink {
		flex-shrink: 1;
	}
	.sm-flex-auto, .sm-flex-shrink {
		flex-basis: auto;
	}
	.sm-flex-auto, .sm-flex-fluid, .sm-flex-shrink {
		min-width: 0;
	}
	.sm-flex-auto, .sm-flex-fluid, .sm-flex-shrink {
		min-height: 0;
	}
	.sm-flex-fluid {
		flex-basis: 0px;
	}
	.sm-flex-shrink {
		flex-grow: 0;
	}
	.sm-flex-grow {
		flex-shrink: 0;
	}
	.sm-flex-column {
		flex-direction: column;
	}
	.sm-flex-wrap {
		flex-wrap: wrap;
	}
	.sm-float-left {
		float: left;
	}
	.sm-float-right {
		float: right;
	}
	.sm-h0 {
		font-size: 4rem;
	}
	.sm-h1 {
		font-size: 3.875rem;
	}
	.sm-h2 {
		font-size: 3rem;
	}
	.sm-h3 {
		font-size: 2.25rem;
	}
	.sm-h4 {
		font-size: 1.75rem;
	}
	.sm-h5 {
		font-size: 1.5rem;
	}
	.sm-h6 {
		font-size: 1.25rem;
	}
	.sm-height-100 {
		height: 100%;
	}
	.sm-height-auto {
		height: auto;
	}
	.sm-justify-start {
		justify-content: flex-start;
	}
	.sm-justify-end {
		justify-content: flex-end;
	}
	.sm-justify-center {
		justify-content: center;
	}
	.sm-justify-between {
		justify-content: space-between;
	}
	.sm-justify-around {
		justify-content: space-around;
	}
	.sm-letter-spacing {
		letter-spacing: .1em;
	}
	.sm-m0, .sm-mt0, .sm-my0 {
		margin-top: 0;
	}
	.sm-m1, .sm-mt1, .sm-my1 {
		margin-top: 0.25rem;
	}
	.sm-m2, .sm-mt2, .sm-my2 {
		margin-top: 0.5rem;
	}
	.sm-m3, .sm-mt3, .sm-my3 {
		margin-top: 1rem;
	}
	.sm-m4, .sm-mt4, .sm-my4 {
		margin-top: 2rem;
	}
	.sm-m5, .sm-mt5, .sm-my5 {
		margin-top: 4rem;
	}
	.sm-m6, .sm-mt6, .sm-my6 {
		margin-top: 8rem;
	}
	.sm-mn1, .sm-mtn1, .sm-myn1 {
		margin-top: -0.25rem;
	}
	.sm-mn2, .sm-mtn2, .sm-myn2 {
		margin-top: -0.5rem;
	}
	.sm-mn3, .sm-mtn3, .sm-myn3 {
		margin-top: -1rem;
	}
	.sm-mn4, .sm-mtn4, .sm-myn4 {
		margin-top: -2rem;
	}
	.sm-mn5, .sm-mtn5, .sm-myn5 {
		margin-top: -4rem;
	}
	.sm-mn6, .sm-mtn6, .sm-myn6 {
		margin-top: -8rem;
	}
	.sm-m0, .sm-mr0, .sm-mx0 {
		margin-right: 0;
	}
	.sm-m1, .sm-mr1, .sm-mx1 {
		margin-right: 0.25rem;
	}
	.sm-m2, .sm-mr2, .sm-mx2 {
		margin-right: 0.5rem;
	}
	.sm-m3, .sm-mr3, .sm-mx3 {
		margin-right: 1rem;
	}
	.sm-m4, .sm-mr4, .sm-mx4 {
		margin-right: 2rem;
	}
	.sm-m5, .sm-mr5, .sm-mx5 {
		margin-right: 4rem;
	}
	.sm-m6, .sm-mr6, .sm-mx6 {
		margin-right: 8rem;
	}
	.sm-mn1, .sm-mrn1, .sm-mxn1 {
		margin-right: -0.25rem;
	}
	.sm-mn2, .sm-mrn2, .sm-mxn2 {
		margin-right: -0.5rem;
	}
	.sm-mn3, .sm-mrn3, .sm-mxn3 {
		margin-right: -1rem;
	}
	.sm-mn4, .sm-mrn4, .sm-mxn4 {
		margin-right: -2rem;
	}
	.sm-mn5, .sm-mrn5, .sm-mxn5 {
		margin-right: -4rem;
	}
	.sm-mn6, .sm-mrn6, .sm-mxn6 {
		margin-right: -8rem;
	}
	.sm-m0, .sm-mb0, .sm-my0 {
		margin-bottom: 0;
	}
	.sm-m1, .sm-mb1, .sm-my1 {
		margin-bottom: 0.25rem;
	}
	.sm-m2, .sm-mb2, .sm-my2 {
		margin-bottom: 0.5rem;
	}
	.sm-m3, .sm-mb3, .sm-my3 {
		margin-bottom: 1rem;
	}
	.sm-m4, .sm-mb4, .sm-my4 {
		margin-bottom: 2rem;
	}
	.sm-m5, .sm-mb5, .sm-my5 {
		margin-bottom: 4rem;
	}
	.sm-m6, .sm-mb6, .sm-my6 {
		margin-bottom: 8rem;
	}
	.sm-mbn1, .sm-mn1, .sm-myn1 {
		margin-bottom: -0.25rem;
	}
	.sm-mbn2, .sm-mn2, .sm-myn2 {
		margin-bottom: -0.5rem;
	}
	.sm-mbn3, .sm-mn3, .sm-myn3 {
		margin-bottom: -1rem;
	}
	.sm-mbn4, .sm-mn4, .sm-myn4 {
		margin-bottom: -2rem;
	}
	.sm-mbn5, .sm-mn5, .sm-myn5 {
		margin-bottom: -4rem;
	}
	.sm-mbn6, .sm-mn6, .sm-myn6 {
		margin-bottom: -8rem;
	}
	.sm-m0, .sm-ml0, .sm-mx0 {
		margin-left: 0;
	}
	.sm-m1, .sm-ml1, .sm-mx1 {
		margin-left: 0.25rem;
	}
	.sm-m2, .sm-ml2, .sm-mx2 {
		margin-left: 0.5rem;
	}
	.sm-m3, .sm-ml3, .sm-mx3 {
		margin-left: 1rem;
	}
	.sm-m4, .sm-ml4, .sm-mx4 {
		margin-left: 2rem;
	}
	.sm-m5, .sm-ml5, .sm-mx5 {
		margin-left: 4rem;
	}
	.sm-m6, .sm-ml6, .sm-mx6 {
		margin-left: 8rem;
	}
	.sm-mln1, .sm-mn1, .sm-mxn1 {
		margin-left: -0.25rem;
	}
	.sm-mln2, .sm-mn2, .sm-mxn2 {
		margin-left: -0.5rem;
	}
	.sm-mln3, .sm-mn3, .sm-mxn3 {
		margin-left: -1rem;
	}
	.sm-mln4, .sm-mn4, .sm-mxn4 {
		margin-left: -2rem;
	}
	.sm-mln5, .sm-mn5, .sm-mxn5 {
		margin-left: -4rem;
	}
	.sm-mln6, .sm-mn6, .sm-mxn6 {
		margin-left: -8rem;
	}
	.sm-mx-auto {
		margin-right: auto;
	}
	.sm-mx-auto {
		margin-left: auto;
	}
	.sm-overflow-hidden {
		overflow: hidden;
	}
	.sm-overflow-auto {
		overflow: auto;
	}
	.sm-overflow-scroll {
		overflow: scroll;
	}
	.sm-p0, .sm-pt0, .sm-py0 {
		padding-top: 0;
	}
	.sm-p1, .sm-pt1, .sm-py1 {
		padding-top: 0.25rem;
	}
	.sm-p2, .sm-pt2, .sm-py2 {
		padding-top: 0.5rem;
	}
	.sm-p3, .sm-pt3, .sm-py3 {
		padding-top: 1rem;
	}
	.sm-p4, .sm-pt4, .sm-py4 {
		padding-top: 2rem;
	}
	.sm-p5, .sm-pt5, .sm-py5 {
		padding-top: 4rem;
	}
	.sm-p6, .sm-pt6, .sm-py6 {
		padding-top: 8rem;
	}
	.sm-p0, .sm-pr0, .sm-px0 {
		padding-right: 0;
	}
	.sm-p1, .sm-pr1, .sm-px1 {
		padding-right: 0.25rem;
	}
	.sm-p2, .sm-pr2, .sm-px2 {
		padding-right: 0.5rem;
	}
	.sm-p3, .sm-pr3, .sm-px3 {
		padding-right: 1rem;
	}
	.sm-p4, .sm-pr4, .sm-px4 {
		padding-right: 2rem;
	}
	.sm-p5, .sm-pr5, .sm-px5 {
		padding-right: 4rem;
	}
	.sm-p6, .sm-pr6, .sm-px6 {
		padding-right: 8rem;
	}
	.sm-p0, .sm-pb0, .sm-py0 {
		padding-bottom: 0;
	}
	.sm-p1, .sm-pb1, .sm-py1 {
		padding-bottom: 0.25rem;
	}
	.sm-p2, .sm-pb2, .sm-py2 {
		padding-bottom: 0.5rem;
	}
	.sm-p3, .sm-pb3, .sm-py3 {
		padding-bottom: 1rem;
	}
	.sm-p4, .sm-pb4, .sm-py4 {
		padding-bottom: 2rem;
	}
	.sm-p5, .sm-pb5, .sm-py5 {
		padding-bottom: 4rem;
	}
	.sm-p6, .sm-pb6, .sm-py6 {
		padding-bottom: 8rem;
	}
	.sm-p0, .sm-pl0, .sm-px0 {
		padding-left: 0;
	}
	.sm-p1, .sm-pl1, .sm-px1 {
		padding-left: 0.25rem;
	}
	.sm-p2, .sm-pl2, .sm-px2 {
		padding-left: 0.5rem;
	}
	.sm-p3, .sm-pl3, .sm-px3 {
		padding-left: 1rem;
	}
	.sm-p4, .sm-pl4, .sm-px4 {
		padding-left: 2rem;
	}
	.sm-p5, .sm-pl5, .sm-px5 {
		padding-left: 4rem;
	}
	.sm-p6, .sm-pl6, .sm-px6 {
		padding-left: 8rem;
	}
	.sm-left {
		text-align: left;
	}
	.sm-center {
		text-align: center;
	}
	.sm-right {
		text-align: right;
	}
	.sm-uppercase {
		text-transform: uppercase;
	}
	.sm-text-transform-none {
		text-transform: none;
	}
	.sm-width-100vw {
		width: 100vw;
	}
	.sm-width-auto {
		width: auto;
	}
	.sm-col12, .sm-width-100 {
		width: 100%;
	}
	.sm-col1 {
		width: 8.333333333333332%;
	}
	.sm-col2 {
		width: 16.666666666666664%;
	}
	.sm-col3 {
		width: 25%;
	}
	.sm-col4 {
		width: 33.33333333333333%;
	}
	.sm-col5 {
		width: 41.66666666666667%;
	}
	.sm-col6 {
		width: 50%;
	}
	.sm-col7 {
		width: 58.333333333333336%;
	}
	.sm-col8 {
		width: 66.66666666666666%;
	}
	.sm-col9 {
		width: 75%;
	}
	.sm-col10 {
		width: 83.33333333333334%;
	}
	.sm-col11 {
		width: 91.66666666666666%;
	}
}
@media (min-width: 480px) and (max-width: 959px) {
	.md-content-start {
		align-content: flex-start;
	}
	.md-content-end {
		align-content: flex-end;
	}
	.md-content-center {
		align-content: center;
	}
	.md-content-stretch {
		align-content: stretch;
	}
	.md-content-between {
		align-content: space-between;
	}
	.md-content-around {
		align-content: space-around;
	}
	.md-items-start {
		align-items: flex-start;
	}
	.md-items-end {
		align-items: flex-end;
	}
	.md-items-center {
		align-items: center;
	}
	.md-items-baseline {
		align-items: baseline;
	}
	.md-items-stretch {
		align-items: stretch;
	}
	.md-self-start {
		align-self: flex-start;
	}
	.md-self-end {
		align-self: flex-end;
	}
	.md-self-center {
		align-self: center;
	}
	.md-self-baseline {
		align-self: baseline;
	}
	.md-self-stretch {
		align-self: stretch;
	}
	.md-table {
		display: table;
	}
	.md-block {
		display: block;
	}
	.md-inline-block {
		display: inline-block;
	}
	.md-inline {
		display: inline;
	}
	.md-flex {
		display: flex;
	}
	.md-inline-flex {
		display: inline-flex;
	}
	.md-hide {
		display: none;
	}
	.md-flex-none {
		flex: none;
	}
	.md-flex-auto, .md-flex-fluid, .md-flex-grow {
		flex-grow: 1;
	}
	.md-flex-auto, .md-flex-fluid, .md-flex-shrink {
		flex-shrink: 1;
	}
	.md-flex-auto, .md-flex-shrink {
		flex-basis: auto;
	}
	.md-flex-auto, .md-flex-fluid, .md-flex-shrink {
		min-width: 0;
	}
	.md-flex-auto, .md-flex-fluid, .md-flex-shrink {
		min-height: 0;
	}
	.md-flex-fluid {
		flex-basis: 0px;
	}
	.md-flex-shrink {
		flex-grow: 0;
	}
	.md-flex-grow {
		flex-shrink: 0;
	}
	.md-flex-column {
		flex-direction: column;
	}
	.md-flex-wrap {
		flex-wrap: wrap;
	}
	.md-float-left {
		float: left;
	}
	.md-float-right {
		float: right;
	}
	.md-h0 {
		font-size: 4rem;
	}
	.md-h1 {
		font-size: 3.875rem;
	}
	.md-h2 {
		font-size: 3rem;
	}
	.md-h3 {
		font-size: 2.25rem;
	}
	.md-h4 {
		font-size: 1.75rem;
	}
	.md-h5 {
		font-size: 1.5rem;
	}
	.md-h6 {
		font-size: 1.25rem;
	}
	.md-height-100 {
		height: 100%;
	}
	.md-height-auto {
		height: auto;
	}
	.md-justify-start {
		justify-content: flex-start;
	}
	.md-justify-end {
		justify-content: flex-end;
	}
	.md-justify-center {
		justify-content: center;
	}
	.md-justify-between {
		justify-content: space-between;
	}
	.md-justify-around {
		justify-content: space-around;
	}
	.md-letter-spacing {
		letter-spacing: .1em;
	}
	.md-m0, .md-mt0, .md-my0 {
		margin-top: 0;
	}
	.md-m1, .md-mt1, .md-my1 {
		margin-top: 0.25rem;
	}
	.md-m2, .md-mt2, .md-my2 {
		margin-top: 0.5rem;
	}
	.md-m3, .md-mt3, .md-my3 {
		margin-top: 1rem;
	}
	.md-m4, .md-mt4, .md-my4 {
		margin-top: 2rem;
	}
	.md-m5, .md-mt5, .md-my5 {
		margin-top: 4rem;
	}
	.md-m6, .md-mt6, .md-my6 {
		margin-top: 8rem;
	}
	.md-mn1, .md-mtn1, .md-myn1 {
		margin-top: -0.25rem;
	}
	.md-mn2, .md-mtn2, .md-myn2 {
		margin-top: -0.5rem;
	}
	.md-mn3, .md-mtn3, .md-myn3 {
		margin-top: -1rem;
	}
	.md-mn4, .md-mtn4, .md-myn4 {
		margin-top: -2rem;
	}
	.md-mn5, .md-mtn5, .md-myn5 {
		margin-top: -4rem;
	}
	.md-mn6, .md-mtn6, .md-myn6 {
		margin-top: -8rem;
	}
	.md-m0, .md-mr0, .md-mx0 {
		margin-right: 0;
	}
	.md-m1, .md-mr1, .md-mx1 {
		margin-right: 0.25rem;
	}
	.md-m2, .md-mr2, .md-mx2 {
		margin-right: 0.5rem;
	}
	.md-m3, .md-mr3, .md-mx3 {
		margin-right: 1rem;
	}
	.md-m4, .md-mr4, .md-mx4 {
		margin-right: 2rem;
	}
	.md-m5, .md-mr5, .md-mx5 {
		margin-right: 4rem;
	}
	.md-m6, .md-mr6, .md-mx6 {
		margin-right: 8rem;
	}
	.md-mn1, .md-mrn1, .md-mxn1 {
		margin-right: -0.25rem;
	}
	.md-mn2, .md-mrn2, .md-mxn2 {
		margin-right: -0.5rem;
	}
	.md-mn3, .md-mrn3, .md-mxn3 {
		margin-right: -1rem;
	}
	.md-mn4, .md-mrn4, .md-mxn4 {
		margin-right: -2rem;
	}
	.md-mn5, .md-mrn5, .md-mxn5 {
		margin-right: -4rem;
	}
	.md-mn6, .md-mrn6, .md-mxn6 {
		margin-right: -8rem;
	}
	.md-m0, .md-mb0, .md-my0 {
		margin-bottom: 0;
	}
	.md-m1, .md-mb1, .md-my1 {
		margin-bottom: 0.25rem;
	}
	.md-m2, .md-mb2, .md-my2 {
		margin-bottom: 0.5rem;
	}
	.md-m3, .md-mb3, .md-my3 {
		margin-bottom: 1rem;
	}
	.md-m4, .md-mb4, .md-my4 {
		margin-bottom: 2rem;
	}
	.md-m5, .md-mb5, .md-my5 {
		margin-bottom: 4rem;
	}
	.md-m6, .md-mb6, .md-my6 {
		margin-bottom: 8rem;
	}
	.md-mbn1, .md-mn1, .md-myn1 {
		margin-bottom: -0.25rem;
	}
	.md-mbn2, .md-mn2, .md-myn2 {
		margin-bottom: -0.5rem;
	}
	.md-mbn3, .md-mn3, .md-myn3 {
		margin-bottom: -1rem;
	}
	.md-mbn4, .md-mn4, .md-myn4 {
		margin-bottom: -2rem;
	}
	.md-mbn5, .md-mn5, .md-myn5 {
		margin-bottom: -4rem;
	}
	.md-mbn6, .md-mn6, .md-myn6 {
		margin-bottom: -8rem;
	}
	.md-m0, .md-ml0, .md-mx0 {
		margin-left: 0;
	}
	.md-m1, .md-ml1, .md-mx1 {
		margin-left: 0.25rem;
	}
	.md-m2, .md-ml2, .md-mx2 {
		margin-left: 0.5rem;
	}
	.md-m3, .md-ml3, .md-mx3 {
		margin-left: 1rem;
	}
	.md-m4, .md-ml4, .md-mx4 {
		margin-left: 2rem;
	}
	.md-m5, .md-ml5, .md-mx5 {
		margin-left: 4rem;
	}
	.md-m6, .md-ml6, .md-mx6 {
		margin-left: 8rem;
	}
	.md-mln1, .md-mn1, .md-mxn1 {
		margin-left: -0.25rem;
	}
	.md-mln2, .md-mn2, .md-mxn2 {
		margin-left: -0.5rem;
	}
	.md-mln3, .md-mn3, .md-mxn3 {
		margin-left: -1rem;
	}
	.md-mln4, .md-mn4, .md-mxn4 {
		margin-left: -2rem;
	}
	.md-mln5, .md-mn5, .md-mxn5 {
		margin-left: -4rem;
	}
	.md-mln6, .md-mn6, .md-mxn6 {
		margin-left: -8rem;
	}
	.md-mx-auto {
		margin-right: auto;
	}
	.md-mx-auto {
		margin-left: auto;
	}
	.md-overflow-hidden {
		overflow: hidden;
	}
	.md-overflow-auto {
		overflow: auto;
	}
	.md-overflow-scroll {
		overflow: scroll;
	}
	.md-p0, .md-pt0, .md-py0 {
		padding-top: 0;
	}
	.md-p1, .md-pt1, .md-py1 {
		padding-top: 0.25rem;
	}
	.md-p2, .md-pt2, .md-py2 {
		padding-top: 0.5rem;
	}
	.md-p3, .md-pt3, .md-py3 {
		padding-top: 1rem;
	}
	.md-p4, .md-pt4, .md-py4 {
		padding-top: 2rem;
	}
	.md-p5, .md-pt5, .md-py5 {
		padding-top: 4rem;
	}
	.md-p6, .md-pt6, .md-py6 {
		padding-top: 8rem;
	}
	.md-p0, .md-pr0, .md-px0 {
		padding-right: 0;
	}
	.md-p1, .md-pr1, .md-px1 {
		padding-right: 0.25rem;
	}
	.md-p2, .md-pr2, .md-px2 {
		padding-right: 0.5rem;
	}
	.md-p3, .md-pr3, .md-px3 {
		padding-right: 1rem;
	}
	.md-p4, .md-pr4, .md-px4 {
		padding-right: 2rem;
	}
	.md-p5, .md-pr5, .md-px5 {
		padding-right: 4rem;
	}
	.md-p6, .md-pr6, .md-px6 {
		padding-right: 8rem;
	}
	.md-p0, .md-pb0, .md-py0 {
		padding-bottom: 0;
	}
	.md-p1, .md-pb1, .md-py1 {
		padding-bottom: 0.25rem;
	}
	.md-p2, .md-pb2, .md-py2 {
		padding-bottom: 0.5rem;
	}
	.md-p3, .md-pb3, .md-py3 {
		padding-bottom: 1rem;
	}
	.md-p4, .md-pb4, .md-py4 {
		padding-bottom: 2rem;
	}
	.md-p5, .md-pb5, .md-py5 {
		padding-bottom: 4rem;
	}
	.md-p6, .md-pb6, .md-py6 {
		padding-bottom: 8rem;
	}
	.md-p0, .md-pl0, .md-px0 {
		padding-left: 0;
	}
	.md-p1, .md-pl1, .md-px1 {
		padding-left: 0.25rem;
	}
	.md-p2, .md-pl2, .md-px2 {
		padding-left: 0.5rem;
	}
	.md-p3, .md-pl3, .md-px3 {
		padding-left: 1rem;
	}
	.md-p4, .md-pl4, .md-px4 {
		padding-left: 2rem;
	}
	.md-p5, .md-pl5, .md-px5 {
		padding-left: 4rem;
	}
	.md-p6, .md-pl6, .md-px6 {
		padding-left: 8rem;
	}
	.md-left {
		text-align: left;
	}
	.md-center {
		text-align: center;
	}
	.md-right {
		text-align: right;
	}
	.md-uppercase {
		text-transform: uppercase;
	}
	.md-text-transform-none {
		text-transform: none;
	}
	.md-width-100vw {
		width: 100vw;
	}
	.md-width-auto {
		width: auto;
	}
	.md-col12, .md-width-100 {
		width: 100%;
	}
	.md-col1 {
		width: 8.333333333333332%;
	}
	.md-col2 {
		width: 16.666666666666664%;
	}
	.md-col3 {
		width: 25%;
	}
	.md-col4 {
		width: 33.33333333333333%;
	}
	.md-col5 {
		width: 41.66666666666667%;
	}
	.md-col6 {
		width: 50%;
	}
	.md-col7 {
		width: 58.333333333333336%;
	}
	.md-col8 {
		width: 66.66666666666666%;
	}
	.md-col9 {
		width: 75%;
	}
	.md-col10 {
		width: 83.33333333333334%;
	}
	.md-col11 {
		width: 91.66666666666666%;
	}
}
@media (min-width: 960px) {
	.lg-content-start {
		align-content: flex-start;
	}
	.lg-content-end {
		align-content: flex-end;
	}
	.lg-content-center {
		align-content: center;
	}
	.lg-content-stretch {
		align-content: stretch;
	}
	.lg-content-between {
		align-content: space-between;
	}
	.lg-content-around {
		align-content: space-around;
	}
	.lg-items-start {
		align-items: flex-start;
	}
	.lg-items-end {
		align-items: flex-end;
	}
	.lg-items-center {
		align-items: center;
	}
	.lg-items-baseline {
		align-items: baseline;
	}
	.lg-items-stretch {
		align-items: stretch;
	}
	.lg-self-start {
		align-self: flex-start;
	}
	.lg-self-end {
		align-self: flex-end;
	}
	.lg-self-center {
		align-self: center;
	}
	.lg-self-baseline {
		align-self: baseline;
	}
	.lg-self-stretch {
		align-self: stretch;
	}
	.lg-table {
		display: table;
	}
	.lg-block {
		display: block;
	}
	.lg-inline-block {
		display: inline-block;
	}
	.lg-inline {
		display: inline;
	}
	.lg-flex {
		display: flex;
	}
	.lg-inline-flex {
		display: inline-flex;
	}
	.lg-hide {
		display: none;
	}
	.lg-flex-none {
		flex: none;
	}
	.lg-flex-auto, .lg-flex-fluid, .lg-flex-grow {
		flex-grow: 1;
	}
	.lg-flex-auto, .lg-flex-fluid, .lg-flex-shrink {
		flex-shrink: 1;
	}
	.lg-flex-auto, .lg-flex-shrink {
		flex-basis: auto;
	}
	.lg-flex-auto, .lg-flex-fluid, .lg-flex-shrink {
		min-width: 0;
	}
	.lg-flex-auto, .lg-flex-fluid, .lg-flex-shrink {
		min-height: 0;
	}
	.lg-flex-fluid {
		flex-basis: 0px;
	}
	.lg-flex-shrink {
		flex-grow: 0;
	}
	.lg-flex-grow {
		flex-shrink: 0;
	}
	.lg-flex-column {
		flex-direction: column;
	}
	.lg-flex-wrap {
		flex-wrap: wrap;
	}
	.lg-float-left {
		float: left;
	}
	.lg-float-right {
		float: right;
	}
	.lg-h0 {
		font-size: 4rem;
	}
	.lg-h1 {
		font-size: 3.875rem;
	}
	.lg-h2 {
		font-size: 3rem;
	}
	.lg-h3 {
		font-size: 2.25rem;
	}
	.lg-h4 {
		font-size: 1.75rem;
	}
	.lg-h5 {
		font-size: 1.5rem;
	}
	.lg-h6 {
		font-size: 1.25rem;
	}
	.lg-height-100 {
		height: 100%;
	}
	.lg-height-auto {
		height: auto;
	}
	.lg-justify-start {
		justify-content: flex-start;
	}
	.lg-justify-end {
		justify-content: flex-end;
	}
	.lg-justify-center {
		justify-content: center;
	}
	.lg-justify-between {
		justify-content: space-between;
	}
	.lg-justify-around {
		justify-content: space-around;
	}
	.lg-letter-spacing {
		letter-spacing: .1em;
	}
	.lg-m0, .lg-mt0, .lg-my0 {
		margin-top: 0;
	}
	.lg-m1, .lg-mt1, .lg-my1 {
		margin-top: 0.25rem;
	}
	.lg-m2, .lg-mt2, .lg-my2 {
		margin-top: 0.5rem;
	}
	.lg-m3, .lg-mt3, .lg-my3 {
		margin-top: 1rem;
	}
	.lg-m4, .lg-mt4, .lg-my4 {
		margin-top: 2rem;
	}
	.lg-m5, .lg-mt5, .lg-my5 {
		margin-top: 4rem;
	}
	.lg-m6, .lg-mt6, .lg-my6 {
		margin-top: 8rem;
	}
	.lg-mn1, .lg-mtn1, .lg-myn1 {
		margin-top: -0.25rem;
	}
	.lg-mn2, .lg-mtn2, .lg-myn2 {
		margin-top: -0.5rem;
	}
	.lg-mn3, .lg-mtn3, .lg-myn3 {
		margin-top: -1rem;
	}
	.lg-mn4, .lg-mtn4, .lg-myn4 {
		margin-top: -2rem;
	}
	.lg-mn5, .lg-mtn5, .lg-myn5 {
		margin-top: -4rem;
	}
	.lg-mn6, .lg-mtn6, .lg-myn6 {
		margin-top: -8rem;
	}
	.lg-m0, .lg-mr0, .lg-mx0 {
		margin-right: 0;
	}
	.lg-m1, .lg-mr1, .lg-mx1 {
		margin-right: 0.25rem;
	}
	.lg-m2, .lg-mr2, .lg-mx2 {
		margin-right: 0.5rem;
	}
	.lg-m3, .lg-mr3, .lg-mx3 {
		margin-right: 1rem;
	}
	.lg-m4, .lg-mr4, .lg-mx4 {
		margin-right: 2rem;
	}
	.lg-m5, .lg-mr5, .lg-mx5 {
		margin-right: 4rem;
	}
	.lg-m6, .lg-mr6, .lg-mx6 {
		margin-right: 8rem;
	}
	.lg-mn1, .lg-mrn1, .lg-mxn1 {
		margin-right: -0.25rem;
	}
	.lg-mn2, .lg-mrn2, .lg-mxn2 {
		margin-right: -0.5rem;
	}
	.lg-mn3, .lg-mrn3, .lg-mxn3 {
		margin-right: -1rem;
	}
	.lg-mn4, .lg-mrn4, .lg-mxn4 {
		margin-right: -2rem;
	}
	.lg-mn5, .lg-mrn5, .lg-mxn5 {
		margin-right: -4rem;
	}
	.lg-mn6, .lg-mrn6, .lg-mxn6 {
		margin-right: -8rem;
	}
	.lg-m0, .lg-mb0, .lg-my0 {
		margin-bottom: 0;
	}
	.lg-m1, .lg-mb1, .lg-my1 {
		margin-bottom: 0.25rem;
	}
	.lg-m2, .lg-mb2, .lg-my2 {
		margin-bottom: 0.5rem;
	}
	.lg-m3, .lg-mb3, .lg-my3 {
		margin-bottom: 1rem;
	}
	.lg-m4, .lg-mb4, .lg-my4 {
		margin-bottom: 2rem;
	}
	.lg-m5, .lg-mb5, .lg-my5 {
		margin-bottom: 4rem;
	}
	.lg-m6, .lg-mb6, .lg-my6 {
		margin-bottom: 8rem;
	}
	.lg-mbn1, .lg-mn1, .lg-myn1 {
		margin-bottom: -0.25rem;
	}
	.lg-mbn2, .lg-mn2, .lg-myn2 {
		margin-bottom: -0.5rem;
	}
	.lg-mbn3, .lg-mn3, .lg-myn3 {
		margin-bottom: -1rem;
	}
	.lg-mbn4, .lg-mn4, .lg-myn4 {
		margin-bottom: -2rem;
	}
	.lg-mbn5, .lg-mn5, .lg-myn5 {
		margin-bottom: -4rem;
	}
	.lg-mbn6, .lg-mn6, .lg-myn6 {
		margin-bottom: -8rem;
	}
	.lg-m0, .lg-ml0, .lg-mx0 {
		margin-left: 0;
	}
	.lg-m1, .lg-ml1, .lg-mx1 {
		margin-left: 0.25rem;
	}
	.lg-m2, .lg-ml2, .lg-mx2 {
		margin-left: 0.5rem;
	}
	.lg-m3, .lg-ml3, .lg-mx3 {
		margin-left: 1rem;
	}
	.lg-m4, .lg-ml4, .lg-mx4 {
		margin-left: 2rem;
	}
	.lg-m5, .lg-ml5, .lg-mx5 {
		margin-left: 4rem;
	}
	.lg-m6, .lg-ml6, .lg-mx6 {
		margin-left: 8rem;
	}
	.lg-mln1, .lg-mn1, .lg-mxn1 {
		margin-left: -0.25rem;
	}
	.lg-mln2, .lg-mn2, .lg-mxn2 {
		margin-left: -0.5rem;
	}
	.lg-mln3, .lg-mn3, .lg-mxn3 {
		margin-left: -1rem;
	}
	.lg-mln4, .lg-mn4, .lg-mxn4 {
		margin-left: -2rem;
	}
	.lg-mln5, .lg-mn5, .lg-mxn5 {
		margin-left: -4rem;
	}
	.lg-mln6, .lg-mn6, .lg-mxn6 {
		margin-left: -8rem;
	}
	.lg-mx-auto {
		margin-right: auto;
	}
	.lg-mx-auto {
		margin-left: auto;
	}
	.lg-overflow-hidden {
		overflow: hidden;
	}
	.lg-overflow-auto {
		overflow: auto;
	}
	.lg-overflow-scroll {
		overflow: scroll;
	}
	.lg-p0, .lg-pt0, .lg-py0 {
		padding-top: 0;
	}
	.lg-p1, .lg-pt1, .lg-py1 {
		padding-top: 0.25rem;
	}
	.lg-p2, .lg-pt2, .lg-py2 {
		padding-top: 0.5rem;
	}
	.lg-p3, .lg-pt3, .lg-py3 {
		padding-top: 1rem;
	}
	.lg-p4, .lg-pt4, .lg-py4 {
		padding-top: 2rem;
	}
	.lg-p5, .lg-pt5, .lg-py5 {
		padding-top: 4rem;
	}
	.lg-p6, .lg-pt6, .lg-py6 {
		padding-top: 8rem;
	}
	.lg-p0, .lg-pr0, .lg-px0 {
		padding-right: 0;
	}
	.lg-p1, .lg-pr1, .lg-px1 {
		padding-right: 0.25rem;
	}
	.lg-p2, .lg-pr2, .lg-px2 {
		padding-right: 0.5rem;
	}
	.lg-p3, .lg-pr3, .lg-px3 {
		padding-right: 1rem;
	}
	.lg-p4, .lg-pr4, .lg-px4 {
		padding-right: 2rem;
	}
	.lg-p5, .lg-pr5, .lg-px5 {
		padding-right: 4rem;
	}
	.lg-p6, .lg-pr6, .lg-px6 {
		padding-right: 8rem;
	}
	.lg-p0, .lg-pb0, .lg-py0 {
		padding-bottom: 0;
	}
	.lg-p1, .lg-pb1, .lg-py1 {
		padding-bottom: 0.25rem;
	}
	.lg-p2, .lg-pb2, .lg-py2 {
		padding-bottom: 0.5rem;
	}
	.lg-p3, .lg-pb3, .lg-py3 {
		padding-bottom: 1rem;
	}
	.lg-p4, .lg-pb4, .lg-py4 {
		padding-bottom: 2rem;
	}
	.lg-p5, .lg-pb5, .lg-py5 {
		padding-bottom: 4rem;
	}
	.lg-p6, .lg-pb6, .lg-py6 {
		padding-bottom: 8rem;
	}
	.lg-p0, .lg-pl0, .lg-px0 {
		padding-left: 0;
	}
	.lg-p1, .lg-pl1, .lg-px1 {
		padding-left: 0.25rem;
	}
	.lg-p2, .lg-pl2, .lg-px2 {
		padding-left: 0.5rem;
	}
	.lg-p3, .lg-pl3, .lg-px3 {
		padding-left: 1rem;
	}
	.lg-p4, .lg-pl4, .lg-px4 {
		padding-left: 2rem;
	}
	.lg-p5, .lg-pl5, .lg-px5 {
		padding-left: 4rem;
	}
	.lg-p6, .lg-pl6, .lg-px6 {
		padding-left: 8rem;
	}
	.lg-left {
		text-align: left;
	}
	.lg-center {
		text-align: center;
	}
	.lg-right {
		text-align: right;
	}
	.lg-uppercase {
		text-transform: uppercase;
	}
	.lg-text-transform-none {
		text-transform: none;
	}
	.lg-width-100vw {
		width: 100vw;
	}
	.lg-width-auto {
		width: auto;
	}
	.lg-col12, .lg-width-100 {
		width: 100%;
	}
	.lg-col1 {
		width: 8.333333333333332%;
	}
	.lg-col2 {
		width: 16.666666666666664%;
	}
	.lg-col3 {
		width: 25%;
	}
	.lg-col4 {
		width: 33.33333333333333%;
	}
	.lg-col5 {
		width: 41.66666666666667%;
	}
	.lg-col6 {
		width: 50%;
	}
	.lg-col7 {
		width: 58.333333333333336%;
	}
	.lg-col8 {
		width: 66.66666666666666%;
	}
	.lg-col9 {
		width: 75%;
	}
	.lg-col10 {
		width: 83.33333333333334%;
	}
	.lg-col11 {
		width: 91.66666666666666%;
	}
}
@media (min-width: 1200px) {
	.xl-content-start {
		align-content: flex-start;
	}
	.xl-content-end {
		align-content: flex-end;
	}
	.xl-content-center {
		align-content: center;
	}
	.xl-content-stretch {
		align-content: stretch;
	}
	.xl-content-between {
		align-content: space-between;
	}
	.xl-content-around {
		align-content: space-around;
	}
	.xl-items-start {
		align-items: flex-start;
	}
	.xl-items-end {
		align-items: flex-end;
	}
	.xl-items-center {
		align-items: center;
	}
	.xl-items-baseline {
		align-items: baseline;
	}
	.xl-items-stretch {
		align-items: stretch;
	}
	.xl-self-start {
		align-self: flex-start;
	}
	.xl-self-end {
		align-self: flex-end;
	}
	.xl-self-center {
		align-self: center;
	}
	.xl-self-baseline {
		align-self: baseline;
	}
	.xl-self-stretch {
		align-self: stretch;
	}
	.xl-table {
		display: table;
	}
	.xl-block {
		display: block;
	}
	.xl-inline-block {
		display: inline-block;
	}
	.xl-inline {
		display: inline;
	}
	.xl-flex {
		display: flex;
	}
	.xl-inline-flex {
		display: inline-flex;
	}
	.xl-hide {
		display: none;
	}
	.xl-flex-none {
		flex: none;
	}
	.xl-flex-auto, .xl-flex-fluid, .xl-flex-grow {
		flex-grow: 1;
	}
	.xl-flex-auto, .xl-flex-fluid, .xl-flex-shrink {
		flex-shrink: 1;
	}
	.xl-flex-auto, .xl-flex-shrink {
		flex-basis: auto;
	}
	.xl-flex-auto, .xl-flex-fluid, .xl-flex-shrink {
		min-width: 0;
	}
	.xl-flex-auto, .xl-flex-fluid, .xl-flex-shrink {
		min-height: 0;
	}
	.xl-flex-fluid {
		flex-basis: 0px;
	}
	.xl-flex-shrink {
		flex-grow: 0;
	}
	.xl-flex-grow {
		flex-shrink: 0;
	}
	.xl-flex-column {
		flex-direction: column;
	}
	.xl-flex-wrap {
		flex-wrap: wrap;
	}
	.xl-float-left {
		float: left;
	}
	.xl-float-right {
		float: right;
	}
	.xl-h0 {
		font-size: 4rem;
	}
	.xl-h1 {
		font-size: 3.875rem;
	}
	.xl-h2 {
		font-size: 3rem;
	}
	.xl-h3 {
		font-size: 2.25rem;
	}
	.xl-h4 {
		font-size: 1.75rem;
	}
	.xl-h5 {
		font-size: 1.5rem;
	}
	.xl-h6 {
		font-size: 1.25rem;
	}
	.xl-height-100 {
		height: 100%;
	}
	.xl-height-auto {
		height: auto;
	}
	.xl-justify-start {
		justify-content: flex-start;
	}
	.xl-justify-end {
		justify-content: flex-end;
	}
	.xl-justify-center {
		justify-content: center;
	}
	.xl-justify-between {
		justify-content: space-between;
	}
	.xl-justify-around {
		justify-content: space-around;
	}
	.xl-letter-spacing {
		letter-spacing: .1em;
	}
	.xl-m0, .xl-mt0, .xl-my0 {
		margin-top: 0;
	}
	.xl-m1, .xl-mt1, .xl-my1 {
		margin-top: 0.25rem;
	}
	.xl-m2, .xl-mt2, .xl-my2 {
		margin-top: 0.5rem;
	}
	.xl-m3, .xl-mt3, .xl-my3 {
		margin-top: 1rem;
	}
	.xl-m4, .xl-mt4, .xl-my4 {
		margin-top: 2rem;
	}
	.xl-m5, .xl-mt5, .xl-my5 {
		margin-top: 4rem;
	}
	.xl-m6, .xl-mt6, .xl-my6 {
		margin-top: 8rem;
	}
	.xl-mn1, .xl-mtn1, .xl-myn1 {
		margin-top: -0.25rem;
	}
	.xl-mn2, .xl-mtn2, .xl-myn2 {
		margin-top: -0.5rem;
	}
	.xl-mn3, .xl-mtn3, .xl-myn3 {
		margin-top: -1rem;
	}
	.xl-mn4, .xl-mtn4, .xl-myn4 {
		margin-top: -2rem;
	}
	.xl-mn5, .xl-mtn5, .xl-myn5 {
		margin-top: -4rem;
	}
	.xl-mn6, .xl-mtn6, .xl-myn6 {
		margin-top: -8rem;
	}
	.xl-m0, .xl-mr0, .xl-mx0 {
		margin-right: 0;
	}
	.xl-m1, .xl-mr1, .xl-mx1 {
		margin-right: 0.25rem;
	}
	.xl-m2, .xl-mr2, .xl-mx2 {
		margin-right: 0.5rem;
	}
	.xl-m3, .xl-mr3, .xl-mx3 {
		margin-right: 1rem;
	}
	.xl-m4, .xl-mr4, .xl-mx4 {
		margin-right: 2rem;
	}
	.xl-m5, .xl-mr5, .xl-mx5 {
		margin-right: 4rem;
	}
	.xl-m6, .xl-mr6, .xl-mx6 {
		margin-right: 8rem;
	}
	.xl-mn1, .xl-mrn1, .xl-mxn1 {
		margin-right: -0.25rem;
	}
	.xl-mn2, .xl-mrn2, .xl-mxn2 {
		margin-right: -0.5rem;
	}
	.xl-mn3, .xl-mrn3, .xl-mxn3 {
		margin-right: -1rem;
	}
	.xl-mn4, .xl-mrn4, .xl-mxn4 {
		margin-right: -2rem;
	}
	.xl-mn5, .xl-mrn5, .xl-mxn5 {
		margin-right: -4rem;
	}
	.xl-mn6, .xl-mrn6, .xl-mxn6 {
		margin-right: -8rem;
	}
	.xl-m0, .xl-mb0, .xl-my0 {
		margin-bottom: 0;
	}
	.xl-m1, .xl-mb1, .xl-my1 {
		margin-bottom: 0.25rem;
	}
	.xl-m2, .xl-mb2, .xl-my2 {
		margin-bottom: 0.5rem;
	}
	.xl-m3, .xl-mb3, .xl-my3 {
		margin-bottom: 1rem;
	}
	.xl-m4, .xl-mb4, .xl-my4 {
		margin-bottom: 2rem;
	}
	.xl-m5, .xl-mb5, .xl-my5 {
		margin-bottom: 4rem;
	}
	.xl-m6, .xl-mb6, .xl-my6 {
		margin-bottom: 8rem;
	}
	.xl-mbn1, .xl-mn1, .xl-myn1 {
		margin-bottom: -0.25rem;
	}
	.xl-mbn2, .xl-mn2, .xl-myn2 {
		margin-bottom: -0.5rem;
	}
	.xl-mbn3, .xl-mn3, .xl-myn3 {
		margin-bottom: -1rem;
	}
	.xl-mbn4, .xl-mn4, .xl-myn4 {
		margin-bottom: -2rem;
	}
	.xl-mbn5, .xl-mn5, .xl-myn5 {
		margin-bottom: -4rem;
	}
	.xl-mbn6, .xl-mn6, .xl-myn6 {
		margin-bottom: -8rem;
	}
	.xl-m0, .xl-ml0, .xl-mx0 {
		margin-left: 0;
	}
	.xl-m1, .xl-ml1, .xl-mx1 {
		margin-left: 0.25rem;
	}
	.xl-m2, .xl-ml2, .xl-mx2 {
		margin-left: 0.5rem;
	}
	.xl-m3, .xl-ml3, .xl-mx3 {
		margin-left: 1rem;
	}
	.xl-m4, .xl-ml4, .xl-mx4 {
		margin-left: 2rem;
	}
	.xl-m5, .xl-ml5, .xl-mx5 {
		margin-left: 4rem;
	}
	.xl-m6, .xl-ml6, .xl-mx6 {
		margin-left: 8rem;
	}
	.xl-mln1, .xl-mn1, .xl-mxn1 {
		margin-left: -0.25rem;
	}
	.xl-mln2, .xl-mn2, .xl-mxn2 {
		margin-left: -0.5rem;
	}
	.xl-mln3, .xl-mn3, .xl-mxn3 {
		margin-left: -1rem;
	}
	.xl-mln4, .xl-mn4, .xl-mxn4 {
		margin-left: -2rem;
	}
	.xl-mln5, .xl-mn5, .xl-mxn5 {
		margin-left: -4rem;
	}
	.xl-mln6, .xl-mn6, .xl-mxn6 {
		margin-left: -8rem;
	}
	.xl-mx-auto {
		margin-right: auto;
	}
	.xl-mx-auto {
		margin-left: auto;
	}
	.xl-overflow-hidden {
		overflow: hidden;
	}
	.xl-overflow-auto {
		overflow: auto;
	}
	.xl-overflow-scroll {
		overflow: scroll;
	}
	.xl-p0, .xl-pt0, .xl-py0 {
		padding-top: 0;
	}
	.xl-p1, .xl-pt1, .xl-py1 {
		padding-top: 0.25rem;
	}
	.xl-p2, .xl-pt2, .xl-py2 {
		padding-top: 0.5rem;
	}
	.xl-p3, .xl-pt3, .xl-py3 {
		padding-top: 1rem;
	}
	.xl-p4, .xl-pt4, .xl-py4 {
		padding-top: 2rem;
	}
	.xl-p5, .xl-pt5, .xl-py5 {
		padding-top: 4rem;
	}
	.xl-p6, .xl-pt6, .xl-py6 {
		padding-top: 8rem;
	}
	.xl-p0, .xl-pr0, .xl-px0 {
		padding-right: 0;
	}
	.xl-p1, .xl-pr1, .xl-px1 {
		padding-right: 0.25rem;
	}
	.xl-p2, .xl-pr2, .xl-px2 {
		padding-right: 0.5rem;
	}
	.xl-p3, .xl-pr3, .xl-px3 {
		padding-right: 1rem;
	}
	.xl-p4, .xl-pr4, .xl-px4 {
		padding-right: 2rem;
	}
	.xl-p5, .xl-pr5, .xl-px5 {
		padding-right: 4rem;
	}
	.xl-p6, .xl-pr6, .xl-px6 {
		padding-right: 8rem;
	}
	.xl-p0, .xl-pb0, .xl-py0 {
		padding-bottom: 0;
	}
	.xl-p1, .xl-pb1, .xl-py1 {
		padding-bottom: 0.25rem;
	}
	.xl-p2, .xl-pb2, .xl-py2 {
		padding-bottom: 0.5rem;
	}
	.xl-p3, .xl-pb3, .xl-py3 {
		padding-bottom: 1rem;
	}
	.xl-p4, .xl-pb4, .xl-py4 {
		padding-bottom: 2rem;
	}
	.xl-p5, .xl-pb5, .xl-py5 {
		padding-bottom: 4rem;
	}
	.xl-p6, .xl-pb6, .xl-py6 {
		padding-bottom: 8rem;
	}
	.xl-p0, .xl-pl0, .xl-px0 {
		padding-left: 0;
	}
	.xl-p1, .xl-pl1, .xl-px1 {
		padding-left: 0.25rem;
	}
	.xl-p2, .xl-pl2, .xl-px2 {
		padding-left: 0.5rem;
	}
	.xl-p3, .xl-pl3, .xl-px3 {
		padding-left: 1rem;
	}
	.xl-p4, .xl-pl4, .xl-px4 {
		padding-left: 2rem;
	}
	.xl-p5, .xl-pl5, .xl-px5 {
		padding-left: 4rem;
	}
	.xl-p6, .xl-pl6, .xl-px6 {
		padding-left: 8rem;
	}
	.xl-left {
		text-align: left;
	}
	.xl-center {
		text-align: center;
	}
	.xl-right {
		text-align: right;
	}
	.xl-uppercase {
		text-transform: uppercase;
	}
	.xl-text-transform-none {
		text-transform: none;
	}
	.xl-width-100vw {
		width: 100vw;
	}
	.xl-width-auto {
		width: auto;
	}
	.xl-col12, .xl-width-100 {
		width: 100%;
	}
	.xl-col1 {
		width: 8.333333333333332%;
	}
	.xl-col2 {
		width: 16.666666666666664%;
	}
	.xl-col3 {
		width: 25%;
	}
	.xl-col4 {
		width: 33.33333333333333%;
	}
	.xl-col5 {
		width: 41.66666666666667%;
	}
	.xl-col6 {
		width: 50%;
	}
	.xl-col7 {
		width: 58.333333333333336%;
	}
	.xl-col8 {
		width: 66.66666666666666%;
	}
	.xl-col9 {
		width: 75%;
	}
	.xl-col10 {
		width: 83.33333333333334%;
	}
	.xl-col11 {
		width: 91.66666666666666%;
	}
}
@media (min-width: 1400px) {
	.xxl-content-start {
		align-content: flex-start;
	}
	.xxl-content-end {
		align-content: flex-end;
	}
	.xxl-content-center {
		align-content: center;
	}
	.xxl-content-stretch {
		align-content: stretch;
	}
	.xxl-content-between {
		align-content: space-between;
	}
	.xxl-content-around {
		align-content: space-around;
	}
	.xxl-items-start {
		align-items: flex-start;
	}
	.xxl-items-end {
		align-items: flex-end;
	}
	.xxl-items-center {
		align-items: center;
	}
	.xxl-items-baseline {
		align-items: baseline;
	}
	.xxl-items-stretch {
		align-items: stretch;
	}
	.xxl-self-start {
		align-self: flex-start;
	}
	.xxl-self-end {
		align-self: flex-end;
	}
	.xxl-self-center {
		align-self: center;
	}
	.xxl-self-baseline {
		align-self: baseline;
	}
	.xxl-self-stretch {
		align-self: stretch;
	}
	.xxl-table {
		display: table;
	}
	.xxl-block {
		display: block;
	}
	.xxl-inline-block {
		display: inline-block;
	}
	.xxl-inline {
		display: inline;
	}
	.xxl-flex {
		display: flex;
	}
	.xxl-inline-flex {
		display: inline-flex;
	}
	.xxl-hide {
		display: none;
	}
	.xxl-flex-none {
		flex: none;
	}
	.xxl-flex-auto, .xxl-flex-fluid, .xxl-flex-grow {
		flex-grow: 1;
	}
	.xxl-flex-auto, .xxl-flex-fluid, .xxl-flex-shrink {
		flex-shrink: 1;
	}
	.xxl-flex-auto, .xxl-flex-shrink {
		flex-basis: auto;
	}
	.xxl-flex-auto, .xxl-flex-fluid, .xxl-flex-shrink {
		min-width: 0;
	}
	.xxl-flex-auto, .xxl-flex-fluid, .xxl-flex-shrink {
		min-height: 0;
	}
	.xxl-flex-fluid {
		flex-basis: 0px;
	}
	.xxl-flex-shrink {
		flex-grow: 0;
	}
	.xxl-flex-grow {
		flex-shrink: 0;
	}
	.xxl-flex-column {
		flex-direction: column;
	}
	.xxl-flex-wrap {
		flex-wrap: wrap;
	}
	.xxl-float-left {
		float: left;
	}
	.xxl-float-right {
		float: right;
	}
	.xxl-h0 {
		font-size: 4rem;
	}
	.xxl-h1 {
		font-size: 3.875rem;
	}
	.xxl-h2 {
		font-size: 3rem;
	}
	.xxl-h3 {
		font-size: 2.25rem;
	}
	.xxl-h4 {
		font-size: 1.75rem;
	}
	.xxl-h5 {
		font-size: 1.5rem;
	}
	.xxl-h6 {
		font-size: 1.25rem;
	}
	.xxl-height-100 {
		height: 100%;
	}
	.xxl-height-auto {
		height: auto;
	}
	.xxl-justify-start {
		justify-content: flex-start;
	}
	.xxl-justify-end {
		justify-content: flex-end;
	}
	.xxl-justify-center {
		justify-content: center;
	}
	.xxl-justify-between {
		justify-content: space-between;
	}
	.xxl-justify-around {
		justify-content: space-around;
	}
	.xxl-letter-spacing {
		letter-spacing: .1em;
	}
	.xxl-m0, .xxl-mt0, .xxl-my0 {
		margin-top: 0;
	}
	.xxl-m1, .xxl-mt1, .xxl-my1 {
		margin-top: 0.25rem;
	}
	.xxl-m2, .xxl-mt2, .xxl-my2 {
		margin-top: 0.5rem;
	}
	.xxl-m3, .xxl-mt3, .xxl-my3 {
		margin-top: 1rem;
	}
	.xxl-m4, .xxl-mt4, .xxl-my4 {
		margin-top: 2rem;
	}
	.xxl-m5, .xxl-mt5, .xxl-my5 {
		margin-top: 4rem;
	}
	.xxl-m6, .xxl-mt6, .xxl-my6 {
		margin-top: 8rem;
	}
	.xxl-mn1, .xxl-mtn1, .xxl-myn1 {
		margin-top: -0.25rem;
	}
	.xxl-mn2, .xxl-mtn2, .xxl-myn2 {
		margin-top: -0.5rem;
	}
	.xxl-mn3, .xxl-mtn3, .xxl-myn3 {
		margin-top: -1rem;
	}
	.xxl-mn4, .xxl-mtn4, .xxl-myn4 {
		margin-top: -2rem;
	}
	.xxl-mn5, .xxl-mtn5, .xxl-myn5 {
		margin-top: -4rem;
	}
	.xxl-mn6, .xxl-mtn6, .xxl-myn6 {
		margin-top: -8rem;
	}
	.xxl-m0, .xxl-mr0, .xxl-mx0 {
		margin-right: 0;
	}
	.xxl-m1, .xxl-mr1, .xxl-mx1 {
		margin-right: 0.25rem;
	}
	.xxl-m2, .xxl-mr2, .xxl-mx2 {
		margin-right: 0.5rem;
	}
	.xxl-m3, .xxl-mr3, .xxl-mx3 {
		margin-right: 1rem;
	}
	.xxl-m4, .xxl-mr4, .xxl-mx4 {
		margin-right: 2rem;
	}
	.xxl-m5, .xxl-mr5, .xxl-mx5 {
		margin-right: 4rem;
	}
	.xxl-m6, .xxl-mr6, .xxl-mx6 {
		margin-right: 8rem;
	}
	.xxl-mn1, .xxl-mrn1, .xxl-mxn1 {
		margin-right: -0.25rem;
	}
	.xxl-mn2, .xxl-mrn2, .xxl-mxn2 {
		margin-right: -0.5rem;
	}
	.xxl-mn3, .xxl-mrn3, .xxl-mxn3 {
		margin-right: -1rem;
	}
	.xxl-mn4, .xxl-mrn4, .xxl-mxn4 {
		margin-right: -2rem;
	}
	.xxl-mn5, .xxl-mrn5, .xxl-mxn5 {
		margin-right: -4rem;
	}
	.xxl-mn6, .xxl-mrn6, .xxl-mxn6 {
		margin-right: -8rem;
	}
	.xxl-m0, .xxl-mb0, .xxl-my0 {
		margin-bottom: 0;
	}
	.xxl-m1, .xxl-mb1, .xxl-my1 {
		margin-bottom: 0.25rem;
	}
	.xxl-m2, .xxl-mb2, .xxl-my2 {
		margin-bottom: 0.5rem;
	}
	.xxl-m3, .xxl-mb3, .xxl-my3 {
		margin-bottom: 1rem;
	}
	.xxl-m4, .xxl-mb4, .xxl-my4 {
		margin-bottom: 2rem;
	}
	.xxl-m5, .xxl-mb5, .xxl-my5 {
		margin-bottom: 4rem;
	}
	.xxl-m6, .xxl-mb6, .xxl-my6 {
		margin-bottom: 8rem;
	}
	.xxl-mbn1, .xxl-mn1, .xxl-myn1 {
		margin-bottom: -0.25rem;
	}
	.xxl-mbn2, .xxl-mn2, .xxl-myn2 {
		margin-bottom: -0.5rem;
	}
	.xxl-mbn3, .xxl-mn3, .xxl-myn3 {
		margin-bottom: -1rem;
	}
	.xxl-mbn4, .xxl-mn4, .xxl-myn4 {
		margin-bottom: -2rem;
	}
	.xxl-mbn5, .xxl-mn5, .xxl-myn5 {
		margin-bottom: -4rem;
	}
	.xxl-mbn6, .xxl-mn6, .xxl-myn6 {
		margin-bottom: -8rem;
	}
	.xxl-m0, .xxl-ml0, .xxl-mx0 {
		margin-left: 0;
	}
	.xxl-m1, .xxl-ml1, .xxl-mx1 {
		margin-left: 0.25rem;
	}
	.xxl-m2, .xxl-ml2, .xxl-mx2 {
		margin-left: 0.5rem;
	}
	.xxl-m3, .xxl-ml3, .xxl-mx3 {
		margin-left: 1rem;
	}
	.xxl-m4, .xxl-ml4, .xxl-mx4 {
		margin-left: 2rem;
	}
	.xxl-m5, .xxl-ml5, .xxl-mx5 {
		margin-left: 4rem;
	}
	.xxl-m6, .xxl-ml6, .xxl-mx6 {
		margin-left: 8rem;
	}
	.xxl-mln1, .xxl-mn1, .xxl-mxn1 {
		margin-left: -0.25rem;
	}
	.xxl-mln2, .xxl-mn2, .xxl-mxn2 {
		margin-left: -0.5rem;
	}
	.xxl-mln3, .xxl-mn3, .xxl-mxn3 {
		margin-left: -1rem;
	}
	.xxl-mln4, .xxl-mn4, .xxl-mxn4 {
		margin-left: -2rem;
	}
	.xxl-mln5, .xxl-mn5, .xxl-mxn5 {
		margin-left: -4rem;
	}
	.xxl-mln6, .xxl-mn6, .xxl-mxn6 {
		margin-left: -8rem;
	}
	.xxl-mx-auto {
		margin-right: auto;
	}
	.xxl-mx-auto {
		margin-left: auto;
	}
	.xxl-overflow-hidden {
		overflow: hidden;
	}
	.xxl-overflow-auto {
		overflow: auto;
	}
	.xxl-overflow-scroll {
		overflow: scroll;
	}
	.xxl-p0, .xxl-pt0, .xxl-py0 {
		padding-top: 0;
	}
	.xxl-p1, .xxl-pt1, .xxl-py1 {
		padding-top: 0.25rem;
	}
	.xxl-p2, .xxl-pt2, .xxl-py2 {
		padding-top: 0.5rem;
	}
	.xxl-p3, .xxl-pt3, .xxl-py3 {
		padding-top: 1rem;
	}
	.xxl-p4, .xxl-pt4, .xxl-py4 {
		padding-top: 2rem;
	}
	.xxl-p5, .xxl-pt5, .xxl-py5 {
		padding-top: 4rem;
	}
	.xxl-p6, .xxl-pt6, .xxl-py6 {
		padding-top: 8rem;
	}
	.xxl-p0, .xxl-pr0, .xxl-px0 {
		padding-right: 0;
	}
	.xxl-p1, .xxl-pr1, .xxl-px1 {
		padding-right: 0.25rem;
	}
	.xxl-p2, .xxl-pr2, .xxl-px2 {
		padding-right: 0.5rem;
	}
	.xxl-p3, .xxl-pr3, .xxl-px3 {
		padding-right: 1rem;
	}
	.xxl-p4, .xxl-pr4, .xxl-px4 {
		padding-right: 2rem;
	}
	.xxl-p5, .xxl-pr5, .xxl-px5 {
		padding-right: 4rem;
	}
	.xxl-p6, .xxl-pr6, .xxl-px6 {
		padding-right: 8rem;
	}
	.xxl-p0, .xxl-pb0, .xxl-py0 {
		padding-bottom: 0;
	}
	.xxl-p1, .xxl-pb1, .xxl-py1 {
		padding-bottom: 0.25rem;
	}
	.xxl-p2, .xxl-pb2, .xxl-py2 {
		padding-bottom: 0.5rem;
	}
	.xxl-p3, .xxl-pb3, .xxl-py3 {
		padding-bottom: 1rem;
	}
	.xxl-p4, .xxl-pb4, .xxl-py4 {
		padding-bottom: 2rem;
	}
	.xxl-p5, .xxl-pb5, .xxl-py5 {
		padding-bottom: 4rem;
	}
	.xxl-p6, .xxl-pb6, .xxl-py6 {
		padding-bottom: 8rem;
	}
	.xxl-p0, .xxl-pl0, .xxl-px0 {
		padding-left: 0;
	}
	.xxl-p1, .xxl-pl1, .xxl-px1 {
		padding-left: 0.25rem;
	}
	.xxl-p2, .xxl-pl2, .xxl-px2 {
		padding-left: 0.5rem;
	}
	.xxl-p3, .xxl-pl3, .xxl-px3 {
		padding-left: 1rem;
	}
	.xxl-p4, .xxl-pl4, .xxl-px4 {
		padding-left: 2rem;
	}
	.xxl-p5, .xxl-pl5, .xxl-px5 {
		padding-left: 4rem;
	}
	.xxl-p6, .xxl-pl6, .xxl-px6 {
		padding-left: 8rem;
	}
	.xxl-left {
		text-align: left;
	}
	.xxl-center {
		text-align: center;
	}
	.xxl-right {
		text-align: right;
	}
	.xxl-uppercase {
		text-transform: uppercase;
	}
	.xxl-text-transform-none {
		text-transform: none;
	}
	.xxl-width-100vw {
		width: 100vw;
	}
	.xxl-width-auto {
		width: auto;
	}
	.xxl-col12, .xxl-width-100 {
		width: 100%;
	}
	.xxl-col1 {
		width: 8.333333333333332%;
	}
	.xxl-col2 {
		width: 16.666666666666664%;
	}
	.xxl-col3 {
		width: 25%;
	}
	.xxl-col4 {
		width: 33.33333333333333%;
	}
	.xxl-col5 {
		width: 41.66666666666667%;
	}
	.xxl-col6 {
		width: 50%;
	}
	.xxl-col7 {
		width: 58.333333333333336%;
	}
	.xxl-col8 {
		width: 66.66666666666666%;
	}
	.xxl-col9 {
		width: 75%;
	}
	.xxl-col10 {
		width: 83.33333333333334%;
	}
	.xxl-col11 {
		width: 91.66666666666666%;
	}
}
