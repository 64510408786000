/*!
Theme Name: Timber Child
Author: Cerpus Sverige AB
Author URI: https://cerpus.se/
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Template: timber-starter-theme
*/

@import 'harpy';
@import 'hamburger';

.icon {
  max-height: 16px;
  vertical-align: middle;
  margin-top: -2px;
}
.icon-small {
  @extend .icon;
  max-height: 14px;
}
.list-reset {
  list-style: none;
  @extend .pl0, .mt0, .mb0;
}

.border {
  @extend .border-top;
  @extend .border-right;
  @extend .border-bottom;
  @extend .border-left;
}

.row {
  @extend .sm-mxn3, .md-mxn3, .lg-mxn3;
}
.col {
  @extend .sm-px3, .md-px3, .lg-px3;
}
.py-box {
  @extend .sm-py3, .md-py3, .lg-py3;
}
.box {
  @extend .col;
  @extend .py-box;
}

.container {
  @extend .col12, .mx-auto, .col, .border-box;
}
.container-px0 {
  @extend .col12, .mx-auto, .px0;
}
.container-md {
  @extend .container, .maxw-container-md;
}
.container-md-px0 {
  @extend .container-px0, .maxw-container-md;
}
.container-xl {
  @extend .container, .maxw-container-xl;
}
.container-xl-px0 {
  @extend .container-px0, .maxw-container-xl;
}

.truncate {
  text-overflow: ellipsis;
  @extend .overflow-hidden, .nowrap;
}
.caps {
  @extend .uppercase, .letter-spacing;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

html {
  font-size: 16px;
  @extend .height-100;
}

body {
  overflow-x: hidden;
  background-attachment: fixed;
  @extend .flex, .flex-column, .height-100, .m0, .light;
}

body,
button,
input,
select,
textarea {
  @extend .h6, .lh-copy, .default-font, .black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, .link {
  @extend .green, .underline, .hover-underline-inherit;
}

.link-discreet {
  @extend .color-inherit, .underline-inherit, .hover-underline;
}

.link-reset {
  @extend .color-inherit, .underline-inherit;
}

p {
  @extend .mt0, .mb2;
}

ul, ol {
  @extend .mt1, .mb3;
  @extend .pl4;
}

h1 {
  @extend .h1, .bold, .mb2, .mt4, .lh-heading, .gray;
  @extend .sm-h2;
}
h2 {
  @extend .h2, .bold, .mb2, .mt4, .lh-heading, .gray;
  @extend .sm-h3;
}
h3 {
  @extend .h3, .bold, .mb2, .mt3, .lh-heading, .gray;
  @extend .sm-h4, .sm-uppercase;
}
h4 {
  @extend .h4, .bold, .mb2, .mt3, .lh-heading, .gray;
  @extend .sm-h5;
}
h5 {
  @extend .h5, .bold, .mb2, .mt3, .lh-heading, .gray;
  @extend .sm-h6;
}
h6 {
  @extend .h6, .bold, .mb2, .mt3, .lh-heading, .gray;
}

img {
  @extend .height-auto, .maxw-100, .valign-middle;
}

pre {
  @extend .p3, .bg-lightgray, .rounded, .my3, .overflow-auto, .left;
  max-height: 50vh;
}

.rtf-footer {
  a {
    @extend .color-inherit;
  }
  p {
    @extend .mb1;
  }
  ul {
    @extend .list-reset;
  }
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: .5em solid transparent;
  border-right: .5em solid transparent;
  border-top: .5em solid currentColor;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: .5em solid transparent;
  border-right: .5em solid transparent;
  border-bottom: .5em solid currentColor;
}

// nav {
//   a {
//     // underline-inherit h3 bold black hover-green uppercase
//     color: #3d3c3b;
//     font-size: 20px;
//     font-weight: bold;
//     text-decoration: none;
//     text-transform: uppercase;
//     &:hover {
//       color: #1ba080;
//     }
//   }
// }

// IE9 and above (http://stackoverflow.com/questions/18907131/detecting-ie11-using-css-capability-feature-detection)
@media screen and (min-width:0\0) and (min-resolution: +72dpi) {
  .ie-justify-center-fix {
    margin-left: -100%;
    margin-right: -100%;
  }
}
.read-more {
  // float: left;
  padding: 10px;
  display: inline-block;
  // box-sizing: border-box;
  @extend .bg-gray;
  //
  &:before {
    content: '.';
    display: block;
    clear: both;
  }
}
.icon > img {
  height: 16px;
}

.small {
  font-size: 70%;
}
.fs-80 {
  font-size: 80%;
}
.fs-70 {
  font-size: 70%;
}
.fs-60 {
  font-size: 60%;
}
.navbar a > img, .js-menu-content a > img {
  max-height: 24px;
  border: 2px solid white;
}

.facetwp-search {
  width: 100%;
  @extend .border-box;
}
.facetwp-dropdown, .facetwp-type-fselect .fs-wrap.multiple, .facetwp-type-fselect .fs-wrap.multiple .fs-dropdown {
  width: 100%;
  position: relative;
  @extend .border-box;
}
.facetwp-facet {
  margin-bottom: 0 !important;
}
.facetwp-facet-search .facetwp-search {
  border-style: none;
  @extend .py2, .pl3;
}
.facetwp-facet-search .facetwp-btn {
  top: 4px;
}
.facetwp-selections {
  ul {
    @extend .list-reset;

    li {
      @extend .border, .border-lightgreen, .py2, .pl2, .regular, .bg-green, .mr2;
      border-width: 4px;
      font-size: 70%;

      &:last-child {
        @extend .mr0;
      }
    }
  }
}

.mailster-wrapper {
  @extend .pb3;
}
.mailster-wrapper label {
  @extend .regular, .uppercase;
}
.mailster-list-description {
  display: none !important;
}
.mailster-submit-wrapper {
  @extend .mt4;
  input[type="submit"] {
    border-width: 4px;
    padding: 5px 10px 0;
    @extend .border, .border-lightgray, .white, .bg-gray, .uppercase;
  }
}

.mailster-form .error input, .mailster-form .error select, .mailster-form .error textarea {
  border: none;
}

select {
  background:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519'/></svg>");
  background-color: white;
  background-repeat:no-repeat;
  background-position: right 10px top 15px;
  background-size: 16px 16px;
  padding: 5px 10px;
  width:auto;
  text-shadow:0 -1px 0 rgba(0, 0, 0, 0.25);
  border-radius:0;
  -webkit-border-radius:0;
  -webkit-appearance: none;
  border:0;
  outline:0;
  -webkit-transition:0.3s ease all;
     -moz-transition:0.3s ease all;
      -ms-transition:0.3s ease all;
       -o-transition:0.3s ease all;
          transition:0.3s ease all;
}
.shortcode_wysija {
  .wysija-paragraph label {
    @extend .uppercase, .bold;
  }
  .wysija-checkbox-paragraph label {
    @extend .regular;
  }
}
.wysija-submit-field {
  border: 4px solid gray;
  @extend .white, .bg-gray, .px3, .py2, .border-lightgray;
}

.hidden {
  display: none;
}
